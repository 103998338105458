.booking-form {
  padding: 30px 0;
  .booking-details {
    .lawyer-details {
      border-radius: 4px;
      .lawyer-info {
        color: white;
        border-radius: 4px;        
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 10px;
        p {
          font-size: 16px;
          color: rgba(255,255,255,0.7);
          line-height: 24px;
        }
        h4 {
          line-height: 28px;
        }
      }
      .booking {
        background-color: #f9f9f9;
        border-radius: 3px;
        padding: 32px;
        h5 {
          padding: 0 0 25px;
          margin: 0 0 25px 0 !important;
          border-bottom: 1px solid #e8e8e8;
        }
        .item {
          p {
            font-size: 15px;
            &:first-child {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
.lawyer {
  background-color: #f8f8f8;
  .nav {
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    margin: 30px 5px;
    font-size: 1.2rem;
    div {
      color: #666;
      transition: 0.3s;
      padding-bottom: 15px;
      padding-right: 8px;
      padding-left: 8px;
      border-bottom: 1px solid #e0e0e0;
      text-decoration: none;
      position: relative;
      bottom: -1px;
      cursor: pointer;
    }
    div:hover {
      color: var(--primary-red);
      border-bottom: 1px solid var(--primary-red);
    }
    .active {
      color: var(--primary-red);
      border-bottom: 1px solid var(--primary-red);
    }
  }

  .lawyer-info {
    background: white;
    padding: 20px 30px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    h3 {
      margin: 20px 0;
    }
    .about {
      font-size: 14px;
      margin: 20px 0px;
    }
    .category {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      flex-direction: row;
      div {
        background-color: var(--primary-red);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 10px;
      }
    }
    .lawyer-details {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-left: 0.08em solid #cbcbcb;
      padding: 0 20px;
      .details {
        font-size: 0.86em;
        font-weight: 500;
      }
      .bold {
        font-weight: bold;
        color: var(--primary-red);
      }
      .available {
        color: var(--primary-red);
        font-weight: bold;
      }
      .button {
        border: 1px solid var(--primary-red);
        color: white;
        background: transparent;
        color: var(--primary-red);
        padding: 8px 10px;
        font-weight: 500;
        border-radius: 10px;
        font-size: 1rem;
        transition: 0.2s ease-in-out;
        width: 90%;
        &:hover {
          background-color: var(--primary-red);
          color: white;
        }
      }
    }
  }

  .lawyer-overview {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    .legal-list {
      list-style: none;
      margin: 30px 0;
      padding: 0;
      position: relative;
      &:before {
        background: #ddd;
        bottom: 0;
        content: "";
        left: 8px;
        position: absolute;
        top: 8px;
        width: 2px;
      }
      .tabs {
        position: relative;
        list-style: none;
        .user {
          background: #fff;
          height: 10px;
          left: 4px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 4px;
          width: 10px;
          .before-circle {
            background-color: white;
            border-radius: 50%;
            height: 12px;
            width: 12px;
            border: 2px solid var(--primary-red);
          }
        }
        .content {
          position: relative;
          margin: 0 0 20px 40px;
          padding: 0;
        }
      }
    }
  }

  .credentials {
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    .creds {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 20px;
      padding: 5px 0;
      .name {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .desig {
        margin: 5px 0;
        font-size: 1.2rem;
      }
      .time {
        font-size: 0.8rem;
      }
    }
  }

  #overview {
    .overview-info {
      display: flex;
      .contact {
        background-color: #f2f2f2;
        padding: 8px 14px;
        transition: 0.3s;
        line-height: 17px;
        font-size: 13px;
        margin: 20px 0;
        &:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
        &:last-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          border-left: 1px solid #e0e0e0;
        }
        a {
          color: #555;
          text-decoration: none;
        }
        &:hover {
          background-color: #eaeaea;
        }
      }
    }
  }

  #feedback {
    h5 {
      padding: 0 0 25px;
      margin: 0 0 25px 0 !important;
      border-bottom: 1px solid #e8e8e8;
    }
    p {
      margin: 8px 0;
      font-size: 14px;
      font-weight: bold;
      color: #444;
    }
  }

  .opening {
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 32px;
    h5 {
      padding: 0 0 25px;
      margin: 0 0 25px 0 !important;
      border-bottom: 1px solid #e8e8e8;
    }
    .booking-form {
      margin: 10px 0;
      .danger {
        background-color: #ffebeb;
        color: #d83838;
        margin: 15px 0;
        line-height: 20px;
        font-size: 13px;
        padding: 13px 19px;
        border-radius: 4px;
      }
    }
    ul {
      list-style: none;
      font-size: 14px;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        transition: 0.2s;
        margin: 2px 0;
        span {
          float: right;
          text-align: right;
        }
      }
    }
  }
}

@charset 'utf-8';

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$main-container-border: 0;
$color-text: white;

@import "../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";

@import "../node_modules/@chatscope/chat-ui-kit-styles/themes/default/main.scss";

:root {
  --primary-red: #da1e37;
  --primary-red-light: #f30b0b51;
  --chat-bg: rgb(243, 12, 12);
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Raleway", sans-serif;
  input {
    height: 48px;
    border-radius: none;
    margin-top: 8px;
  }

  select {
    height: 48px;
    border-radius: none;
  }

  select:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0.2px var(--primary-red-light) solid !important;
  }

  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0.2px var(--primary-red-light) solid !important;
  }

  textarea:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0.2px var(--primary-red-light) solid !important;
  }

  .verified {
    color: #19b453;
  }

  .btn-link {
    color: var(--primary-red);
  }
  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }

  .btn-link:hover {
    color: var(--primary-red);
  }

  .ant-picker-outlined:hover {
    border-color: var(--primary-red);
  }

  .ant-picker-outlined:focus-within {
    border-color: var(--primary-red);
  }

  .ant-picker-active-bar {
    background: var(--primary-red) !important;
  }

  .form-switch .form-check-input {
    height: 24px;
    width: 48px;
  }
  .form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
  }
  .form-switch .form-check-input:checked {
    background-color: var(--primary-red);
    border-color: var(--primary-red);
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
  }

  .button {
    background-color: var(--primary-red);
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
  }

  .button-outline {
    background-color: transparent;
    color: var(--primary-red);
    border: none;
    border: 1px solid var(--primary-red);
    padding: 10px 50px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: var(--primary-red);
      color: white;
    }
  }

  .text-red {
    color: var(--primary-red);
  }

  .cs-chat-container {
    .cs-message-separator {
      color: var(--primary-red);
      &::before {
        background-color: var(--primary-red) !important;
      }
      &::after {
        background-color: var(--primary-red) !important;
      }
    }
    .cs-message-input__content-editor-wrapper {
      background-color: white;
      border: 1px solid #dbdbdb;
      margin-top: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);
      .cs-message-input__content-editor {
        background-color: white;
        color: black;
      }
    }
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
      background-color: var(--primary-red-light);
    }
    .ps__thumb-y {
      background-color: var(--primary-red-light);
    }
    .cs-button--send {
      color: var(--primary-red);
    }
    .cs-button--attachment {
      color: var(--primary-red);
    }
    .cs-avatar {
      border: 1px solid var(--primary-red);
    }
    .cs-message {
      max-width: 95%;
    }
    .cs-message__content-wrapper {
      flex: 1 !important;
    }
    .cs-message--outgoing {
      margin: 10px 0;
      align-items: center;
      .cs-message__content {
        background-color: var(--primary-red) !important;
        color: white !important;
        padding-top: 18px;
        font-size: 15px;
        padding-bottom: 18px;
      }
    }
    .cs-message--incoming {
      margin: 10px 0;
      align-items: center;
      .cs-message__content {
        background-color: #f6f6f6 !important;
        color: black !important;
        padding-top: 18px;
        font-size: 15px;
        padding-bottom: 18px;
      }
    }
  }
  .cs-conversation-list {
    .scrollbar-container {
      background: white !important;
      border: 0.085rem solid #d7d7d7 !important;
    }
    .cs-conversation {
      border-bottom: 0.085rem solid #d7d7d7 !important;
      .cs-conversation__name {
        color: var(--primary-red);
      }
    }
  }
}

.footer {
  background-color: #172B4D;
  color: white;
}

.footer .text-muted{
  color: white !important;
  font-size: 0.9rem;
}

.footer .icon {
  padding: 10px;
  margin-right: 15px;
  background: var(--primary);
  color: white;
  border-radius: 50%;
}

.footer .logo {
  margin-right: 10px;
}

.footer .copyright {
  font-size: 1rem;
  font-weight: 500;
}

.getstarted {
  background-image: url(../../assets/footer.webp);
  background-repeat: no-repeat;
  background-size: auto;
  padding: 50px 0;
}

.getstarted input {
  width: 300px;
  padding: 0 20px;
  border-radius: 5px;
  border: none;
}

.getstarted button {
  background-color: #A40016;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
.aboutus {
  .top {
    padding-bottom: 20px;
    h2 {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.25em;
      letter-spacing: 0px;
      .gray {
        color: #7A7A7A;
      }
    }
  }
  p {
    font-size: 18px;
  }
  .team {
    padding: 50px 0;
    background-color: #F5F5F5;
    h1 {
      font-size: 3em;
      line-height: 1.2em;
      letter-spacing: 0;
      font-weight: 600;
      padding: 25px 0;
      margin-bottom: 40px;
      border-bottom: 1px solid black;
    }
    img {
      border-radius: 10px;
    }
    .content {
      margin-top: 25px; 
      h5 {
        font-size: 22px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
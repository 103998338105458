.dashboard {
  height: 100vh;
  width: 100%;
  display: flex;
  .sidebar {
    display: flex;
    height: 100%;
    min-height: 100%;
    .branding {
      padding: 20px
    }
  }
  .contents {
    width: 100%;
    padding: 20px 50px;
    background-color: #f5f5f5;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    .services {
      display: flex;
      margin: 40px 0;
      flex-wrap: wrap;
      gap: 10px 20px;
      .break {
        flex-basis: 100%;
        height: 0;
      }
      .service {
        display: flex;
        align-items: center;
        flex: 1 1;
        justify-content: space-between;
        padding: 30px 35px;
        border-radius: 20px;
        background-color: transparent;
        border-radius: 12px 12px 12px 12px;
        box-shadow: 7px 7px 15px 0px rgba(0, 0, 0, 0.12);
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        img {
          width: 120px;
        }
        h3 {
          color: white;
          margin: 0px 0px 20px 0px;
          font-size: 19px;
          font-weight: bold;
        }
        .service-btn {
          font-size: 12px;
          color: #DA1E37;
          background-color: #FFFFFF;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #FFFFFF;
          padding: 5px 20px;
          border-radius: 5px;
        }
      }
      .queries { 
        background-image: linear-gradient(270deg, #DA1E1E 0%, #9C1919 100%);
        border-color: #DA1E37;
      }
      .lawyer {
        background-image: linear-gradient(270deg, #DA1E1E 0%, #C42513 100%);
        border-color: #DA1E37;
      }
      .help {
        background-image: linear-gradient(270deg, #DA1E37 0%, #F2295B 100%);
        border-color: #DA1E37;
      }
      .draft {
        background-image: linear-gradient(270deg, #DA1E1E 0%, #F94343 100%);
        border-color: #DA1E37;
      }
      .know {
        background-image: linear-gradient(270deg, #DA1E1E 0%, #FF6E6E 100%);
        border-color: #DA1E37;
      }
      .library {
        background-image: linear-gradient(270deg, #DAA21E 0%, #FB7E6F 100%);
        border-color: #DAA21E;
      }
      @media screen and (max-width: 672px) {
        .service {
          flex: 100%;
        }
      }
    }
  }
}
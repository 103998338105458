.legalServices {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
  border-radius: 10px;
  height: 210px;
}

.legalServices .content {
  padding: 20px;
}

.legalServices .content h3 {
  font-weight: bold;
  font-size: 1.2rem;
}
.navbar {
  box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.15);
}

.navbar-button {
  background-color: #A40016;
  color: white !important;
  padding: 8px 30px;
  border-radius: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.nav-item {
  font-size: 16px;
  font-weight: bold;
}

.navbar .nav-item .dropdown-menu {
  background: white;
  border: 1px solid var(--primary-red);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
  margin-top:0px !important;
  width:100% !important;
  
}

.dropdown-menu .dropdown-item {
  font-weight: 600;
}

.show{
  transition: 0.1s ease-in-out;
}

.navbar .nav-item .dropdown-menu .dropdown-item:hover {
  color: var(--primary-red);
  transition: 0.1s ease-in-out;
}

.dropdown-toggle::after {
  content: none;
}

.d-content {
  display: none;
  position: absolute;
  background: white;
  width: 100%;
  left: 0;
  z-index: 10;
  transition: opacity 1s ease-out;
  padding: 30px 0;
}

.d-content h2 { 
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-red)
}

.d-content p {
  font-size: 1rem;
  color: black;
  font-weight: normal;
}

.d-content a {
  text-decoration: none;
}

.d-content .col-md-4 { 
  margin: 10px 0;
  cursor: pointer;
}

.d:hover .d-content {
  display: block;
  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}

@media all and (min-width: 992px) {
.navbar .nav-item .dropdown-menu{ display: none; }
/* .navbar .nav-item:hover .nav-link{   } */
.navbar .nav-item:hover .dropdown-menu{ display: block; }
.navbar .nav-item .dropdown-menu{ margin-top:0; }
}
.lawyers-list {
  .lawyer-profile {
    margin: 20px 0;
    background-color: #f9f9f9;
    border-radius: 0 8px 8px 0;
    transition: 0.3s ease-in-out;
    img {
      width: 250px;
    }

    .lawyer-info {
      padding: 20px 0;
      .category {
        background: #ffffff;
        padding: 8px 20px;
        font-size: 12px;
        margin: 5px;
        border-radius: 5px;
      }
    }

    .lawyer-details {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-left: 0.08em solid #cbcbcb;
      padding: 0 20px;
      .details {
        font-size: 0.86em;
        font-weight: 500;
      }
      .bold {
        font-weight: bold;
        color: var(--primary-red);
      }
      .available {
        color: var(--primary-red);
        font-weight: bold;
      }
      .button {
        border: 1px solid var(--primary-red);
        color: white;
        background: transparent;
        color: var(--primary-red);
        padding: 8px 10px;
        font-weight: 500;
        border-radius: 10px;
        font-size: 1rem;
        transition: 0.2s ease-in-out;
        width: 90%;
        &:hover {
          background-color: var(--primary-red);
          color: white;
        }
      }
    }

    // .lawyer-info {
    //   font-size: 13px;
    //   font-weight: bold;
    //   background-color: #fff;
    //   color: #777;
    //   border-radius: 100px;
    //   padding: 5px 8px;
    //   box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
    //   margin-right: 10px;
    //   .pricing {
    //     background-color: #64bc36;
    //     color: white;
    //     padding: 4px 8px;
    //     border-radius: 100px;
    //   }
    //   .booking {
    //     background-color: #181818;
    //     color: white;
    //     padding: 4px 8px;
    //     border-radius: 100px;
    //   }
    // }

    // p {
    //   font-weight: bold;
    //   transition: 0.3s ease-in-out;
    // }

    img {
      border-radius: 8px 0 0 8px;
    }
  }
}

.signup {
  .nav-link {
    color: #666 !important;
    margin: 0 2px;
    padding: 0.8rem 1rem;
  }
  .active {
    border-color: transparent !important;
    background-color: transparent !important;
    color: var(--primary-red) !important;
    border-bottom-color: var(--primary-red) !important;
  }
  .nav-item {
    font-weight: 500;
    cursor: pointer;
  }
  .nav-link:hover {
    border-color: transparent;
    background-color: transparent !important;
    color: var(--primary-red) !important;
    border-bottom-color: var(--primary-red) !important;
  }
  .button {
    background-color: var(--primary-red);
    margin: 20px 0;
    font-size: 1rem;
  }
  .join {
    color: var(--primary-red) !important;
    text-decoration: none;
  }

  .viewpass{
    float: right;
    margin-left: -25px;
    margin-top: -26px;
    position: relative;
    font-size: large;
    z-index: 2;
  }

  .eyes {
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    padding: 12px 5px;
    border-radius: 5px;
  }

  .login {
    padding: 50px 0;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
    a {
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
    }
  }

  .register {
    padding: 40px 0;
    .form-check-input:checked {
      background-color: var(--primary-red);
      border-color: var(--primary-red);
    }
    .form-check-input:focus {
      box-shadow: none;
    }
    .reg {
      background-color: #f2f2f2;
      font-weight: 500;
      color: #888
    }
    .regactive {
      background-color: var(--primary-red);
      color: white;
      transition: 0.3s ease-in-out;
    }
    .passVal {
      .passerror {
        padding: 20px;
        border-radius: 15px;
        border: 1px solid var(--primary-red);
        color: var(--primary-red);
      }
      .passsuccess {
        padding: 20px;
        border-radius: 15px;
        border: 1px solid green;
        color: green;
      }
      p {
        font-size: 15px;
      }
    }

  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.appointment {
  background-color: #da1e371c;
  padding: 80px 0;
  h1 {
    text-align: center;
    color: var(--primary-red);
    margin-bottom: 30px;
  }
  .booking {
    border-radius: 25px;
    padding: 30px 20px;
  }
  .app {
    background-color: white;
    font-weight: 500;
    color: #888;
  }
  .appactive {
    background-color: var(--primary-red);
    color: white;
    transition: 0.3s ease-in-out;
  }
  .button-outline {
    width: 100%;
  }
  img {
    width: 500px;
  }
  p {
    font-size: 16px;
    line-height: 29px;
  }
  .lawyer-info {
    background-color: white;
    padding: 20px 30px;
    border-radius: 25px;
    img {
      width: 150px;
      border-radius: 50%;
    }
  }
  .booking-form {
    margin: 10px 0;
    .danger {
      background-color: #f64e4e;
      color: white;
      margin: 15px 0;
      line-height: 20px !important;
      font-size: 13px;
      padding: 13px 19px;
      border-radius: 4px;
    }
  }
}

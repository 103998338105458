.inbox {
  margin: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
  border: 0.085rem solid #d7d7d7 !important;
  h4 {
    font-size: 16px;
    margin: 0;
    padding: 22px 30px;
    color: #333;
    background-color: #fff;
    display: block;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px 4px 0 0;
    position: relative;
  }
  .inbox {
    border-radius: 5px;
  }
}

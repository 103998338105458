.booking {
  // margin: 30px 0 30px 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  .heading {
    margin: 0 20px;
    padding: 15px 16px;
    border-radius: 4px 4px 0 0;
    h4 {
      font-size: 20px;
    }
  }
  .filter {
    background-color: #f6f5f5;
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
    .filter-tag {
      padding: 0 20px;
      cursor: pointer;
      font-weight: bold;
    }
    .active {
      background-color: white;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 5px;
    }
  }
  .booking-items {
    border-top: 1px solid #eaeaea;
    .day {
      font-size: 2rem;
      color: var(--primary-red);
    }
    .date {
      font-size: 2rem;
      color: var(--primary-red);
    }
    p {
      color: #666;
      .bold {
        font-weight: bold;
      }
      .date {
        background-color: #e9f7fe;
        color: #3184ae;
        padding: 2px 10px;
        line-height: 27px;
        border-radius: 70px;
        font-weight: 600;
      }
    }
    .status {
      border-radius: 50px;
      line-height: 20px;
      font-weight: 600;
      font-size: 12px;
      color: #fff;
      font-style: normal;
      padding: 3px 8px;
      margin-left: 3px;
    }
    .pending {
      background-color: #3184ae;
    }
    .approved {
      background-color: #64bc36;
    }
    .rejected {
      background-color: #e42929;
    }
    .rejectButton {
      background-color: #e9e9e9;
      color: #666;
      padding: 6px 15px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      border: transparent;
      border-radius: 50px;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: #e42929;
        color: white;
      }
    }

    .approveButton {
      background-color: #e9e9e9;
      color: #666;
      padding: 6px 15px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      border: transparent;
      border-radius: 50px;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: #64bc36;
        color: white;
      }
    }

    .messageButton {
      background-color: #c6e8ff;
      color: #666;
      padding: 6px 15px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      border: transparent;
      border-radius: 50px;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: #a8dcff;
        color: white;
      }
    }
  }
}

.lawyer-profile {
  margin: 20px 0;
  background-color: #f9f9f9;
  border-radius: 0 8px 8px 0;
  transition: 0.3s ease-in-out;
  img {
    width: 250px;
  }

  .lawyer-info {
    padding: 20px 0;
    .category {
      background: #ffffff;
      padding: 8px 20px;
      font-size: 12px;
      margin: 5px;
      border-radius: 5px;
    }
  }

  .lawyer-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-left: 0.08em solid #cbcbcb;
    padding: 0 20px;
    .details {
      font-size: 0.86em;
      font-weight: 500;
    }
    .bold {
      font-weight: bold;
      color: var(--primary-red);
    }
    .available {
      color: var(--primary-red);
      font-weight: bold;
    }
    .button {
      border: 1px solid var(--primary-red);
      color: white;
      background: transparent;
      color: var(--primary-red);
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 10px;
      font-size: 1rem;
      transition: 0.2s ease-in-out;
      width: 90%;
      &:hover {
        background-color: var(--primary-red);
        color: white;
      }
    }
  }

  img {
    border-radius: 8px 0 0 8px;
  }
}

.landing {
  background-image: url(https://..asklegalx.com/wp-content/uploads/2022/12/white-wave-bg.svg),
    linear-gradient(60deg, #a20116, #c23043);
  background-repeat: no-repeat, repeat;
  background-position-x: center, 0%;
  background-position-y: bottom -0.5px, 0%;
  background-size: 100% 14%, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-color: rgb(82, 67, 170);
  background-blend-mode: normal, normal;
  color: rgb(255, 255, 255);
  position: relative;
  .landing-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url(https://asklegalx.com/wp-content/uploads/2022/12/white-wave-bg.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
  }

  .heading {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 1.25em;
    letter-spacing: 0px;
  }

  p {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

.easy {
  margin: 20px 0;
  background: linear-gradient(10deg, rgb(230, 252, 255), rgb(255, 255, 255)) 0%
    0% / auto repeat scroll padding-box border-box rgb(178, 212, 255);
  background-blend-mode: normal;
  color: rgb(9, 30, 66);
  padding: 20px 0;
  .heading {
    width: 50%;
    h1 {
      font-size: 2rem;
    }
    h4 {
      margin: 30px 0;
      font-size: 1.3rem;
    }
  }
  .border-cards {
    border-style: solid;
    border-width: 0px 0px 0px 8px;
    border-color: #00c7e5;
    box-shadow: 0px 8px 16px 0px rgb(9 30 66 / 15%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    border-radius: 5px 5px 5px 5px;
    padding: 20px;
  }

  .border-cards h6 {
    font-weight: bold;
  }

  .cards {
    padding: 20px;
    margin: 10px 0;
  }

  .cards h6 {
    font-weight: bold;
  }
}

.slider {
  .header {
    width: 50%;
  }
  .swiper-wrapper {
    padding: 25px 0;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px !important;
    margin-top: 10px;
  }
}

.whylegalx {
  background-color: #da1e371c;
  position: relative;
  .shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
  }
  .top {
    transform: rotate(180deg);
    top: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    svg {
      height: 53px;
      z-index: -1;
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .bottom {
    bottom: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    svg {
      height: 45px;
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.testimonial {
  // padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  .testimonial-title-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 1rem;
    text-align: center;
    h1 {
      font-size: 3rem;
      line-height: 1;
    }
    p {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }
  .testimonial-container {
    position: relative;
    height: 1273px;
    overflow: hidden;
    .testimonial-slider {
      position: absolute;
      left: 50%;
      display: flex;
      height: 100%;
      transform-origin: top;
      transform: translateX(-50%);
      justify-content: center;
      gap: 1.5rem;
    }
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-top: calc(var(--slide-spacing) * -1);
  }
}

.featured {
  padding: 50px 0;
  h1 {
    text-align: center;
    font-weight: bold;
  }
}

.plans {
  background-color: #f0f0f0d6;
  padding: 5rem 0;
  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .plan-card {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .bgblue {
    background-color: #091e42 !important;
    color: white !important;
  }
  h4 {
    font-size: 2rem;
  }
  .currency {
    font-size: 3.5rem;
    span {
      font-size: 1.5rem;
    }
  }
  .plan-button {
    text-decoration: none;
    background-color: #a40016;
    padding: 12px 70px;
    border-radius: 5px;
    font-size: 1.2rem;
    color: white;
    border: none;
    font-weight: 500;
  }
}

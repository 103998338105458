.profile {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.06);
  margin: 30px 0;
  h3 {
    padding: 30px;
    font-size: 22px;
    border-bottom: 1px solid #f5f5f5;
  }
  .booking {
    display: flex;
    justify-content: space-between;
  }
  .lawyer-form {
    padding: 20px 30px;
    .slots {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
      .days {
        flex: 0 0 20%;
        display: flex;
        flex-direction: column;
        select {
          height: inherit;
          margin: 0 10px;
          padding: 8px 5px;
        }
        p {
          background-color: var(--primary-red);
          padding: 10px 8px;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .slot {
          background-color: #f2f2f2;
          border-radius: 5px;
          padding: 10px 8px;
          input {
            padding: 0px 5px;
          }
        }
      }
    }
    .map {
      width: 100%;
      z-index: 1;
    }
  }
}

.info {
  background-color: #e1f3fc;
  color: #3184ae;
  padding: 18px 10px;
  border-radius: 5px;
  font-size: 15px;
}

.preview {
  h2 {
    font-size: 35px;
    font-weight: normal;
    .category {
      background-color: #f30c0c12;
      margin: 0;
      line-height: 15px;
      font-weight: 500;
      letter-spacing: 0;
      font-size: 14px;
      text-transform: none;
      border: none !important;
      padding: 6px 14px;
      border-radius: 50px;
    }
  }
  h3 {
    padding: 30px 0;
  }
  .nav {
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    margin: 30px 0;
    font-size: 16px;
    a {
      color: #666;
      transition: 0.3s;
      padding-bottom: 15px;
      padding-right: 8px;
      padding-left: 8px;
      border-bottom: 1px solid #e0e0e0;
      text-decoration: none;
      position: relative;
      bottom: -1px;
    }
    a:hover {
      color: var(--primary-red);
      border-bottom: 1px solid var(--primary-red);
    }
    .active {
      color: var(--primary-red);
      border-bottom: 1px solid var(--primary-red);
    }
  }

  #overview {
    .overview-info {
      display: flex;
      .contact {
        background-color: #f2f2f2;
        padding: 8px 14px;
        transition: 0.3s;
        line-height: 17px;
        font-size: 13px;
        margin: 20px 0;
        &:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
        &:last-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          border-left: 1px solid #e0e0e0;
        }
        a {
          color: #555;
          text-decoration: none;
        }
        &:hover {
          background-color: #eaeaea;
        }
      }
    }

    .pricing-info {
      display: flex;
      padding: 6px 0px;
      border-radius: 50px;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      .pricing {
        background-color: #64bc36;
        color: white;
        padding: 8px;
        border-radius: 100px;
        margin-right: 10px;
      }
    }

    .opening {
      background-color: #f9f9f9;
      border-radius: 20px;
      padding: 32px;
      ul {
        list-style: none;
        font-size: 14px;
        margin: 0;
        padding: 0;
        li {
          width: 100%;
          transition: 0.2s;
          margin: 2px 0;
          span {
            float: right;
            text-align: right;
          }
        }
      }
    }
  }

  .submit {
    button {
      font-size: 15px;
      &:first-child {
        margin-right: 10px;
        background-color: transparent;
        border: 2px solid var(--primary-red);
        color: var(--primary-red)
      }
    }
  }
}
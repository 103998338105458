.slider {
  height: 100%;
  overflow: hidden;
  .slider-viewport {
    height: 100%;
    .slider-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      will-change: transform;
      .slider-slide {
        margin-bottom: 1.5rem;
        min-height: fit-content;
        width: 416px;
        transform: translate3d(0px, 0px, 0px);
        .slider-content {
          border: 1px solid #dbdbdb;
          border-radius: 1rem;
          width: 416px;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          gap: 4rem;
          justify-content: space-between;
          .slider-user {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            img {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
            }
            .slider-name {
              font-size: 1rem;
              font-weight: 500;
              margin-bottom: 0;
            }
            .slider-title {
              font-size: 1rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
